import Section from 'components/Section'
import styles from './EmployeeStoriesHero.module.scss'
import { get as _get } from 'lodash-es'
import { DATA_TEST_IDS } from 'constants/test-ids'
import { useMemoizedContentGetter } from 'utils/contentful'
import { Carousel } from 'components/Carousels'
import { MediaImageStandard, Typography } from 'components/Contentful'
import { AchieveTheme, Box, Grid } from '@achieve/ascend'

const FONT_MAP_CLASS = {
  EYEBROW: 'bodySm lg:bodyLg font-bold',
  TITLE: 'headingLg lg:displayXl font-bold',
  COPY: 'bodySm lg:bodyLg font-light',
}

const EmployeeStoriesHero = ({ content }) => {
  const {
    employeeStoriesHeroCopyA,
    employeeStoriesHeroCopyC,
    employeeStoriesHeroEyebrow,
    employeeStoriesHeroImageA,
    employeeStoriesHeroImageC,
    employeeStoriesHeroTitleA,
    employeeStoriesHeroTitleC,
  } = useMemoizedContentGetter(content, [
    'employeeStoriesHeroCopyA',
    'employeeStoriesHeroCopyC',
    'employeeStoriesHeroEyebrow',
    'employeeStoriesHeroImageA',
    'employeeStoriesHeroImageC',
    'employeeStoriesHeroTitleA',
    'employeeStoriesHeroTitleC',
  ])

  const slides = [
    {
      sys: { id: 'item1' },
      eyebrow: employeeStoriesHeroEyebrow?.textContent,
      title: employeeStoriesHeroTitleA?.textContent,
      copy: employeeStoriesHeroCopyA?.textContent,
      background: _get(employeeStoriesHeroImageA, 'mediaContent', ''),
      alt: _get(employeeStoriesHeroImageA, 'mediaAltText', employeeStoriesHeroTitleA?.textContent),
      backgroundMobile: _get(employeeStoriesHeroImageA, 'mobileMediaContent', ''),
      priority: true,
      isMobileUA: content?.isMobileUA,
    },
    {
      sys: { id: 'item3' },
      eyebrow: employeeStoriesHeroEyebrow?.textContent,
      title: employeeStoriesHeroTitleC?.textContent,
      copy: employeeStoriesHeroCopyC?.textContent,
      background: _get(employeeStoriesHeroImageC, 'mediaContent', ''),
      alt: _get(employeeStoriesHeroImageC, 'mediaAltText', employeeStoriesHeroTitleC?.textContent),
      backgroundMobile: _get(employeeStoriesHeroImageC, 'mobileMediaContent', ''),
      isMobileUA: content?.isMobileUA,
    },
  ]

  return (
    <Section childContainerClassName={styles['employee-stories-hero-container']}>
      <EmployeeStoriesCarousel slides={slides} testIDPrefix={DATA_TEST_IDS.EMPLOYEE_STORIES.HERO} />
    </Section>
  )
}

function Slide({ item = {}, selected }) {
  let { alt, background, backgroundMobile, copy, eyebrow, priority, sys, title } = item

  return (
    <Box className={styles['slide-outer']} data-item={sys.id}>
      <div className={styles['slide-inner']}>
        <div className="bg-top sm:bg-center block lg:hidden">
          <MediaImageStandard
            content={backgroundMobile}
            alt={alt}
            layout="fill"
            objectFit="cover"
            priority={priority}
          />
        </div>
        <div className="bg-top sm:bg-center hidden lg:block">
          <MediaImageStandard
            content={background}
            alt={alt}
            layout="fill"
            objectFit="cover"
            priority={priority}
          />
        </div>
        <Grid className={styles['slide-content-container']} container>
          <Grid className={styles['slide-text']} item xs={12} lg={6}>
            <Typography
              content={eyebrow}
              color={AchieveTheme?.ascend?.colors?.blue[500]}
              className={FONT_MAP_CLASS.EYEBROW}
              component={selected ? 'h1' : 'p'}
            />
            <Typography
              content={title}
              className={FONT_MAP_CLASS.TITLE}
              color={AchieveTheme?.ascend?.colors?.neutral?.[900]}
              component="h2"
            />
            <Typography
              content={copy}
              className={FONT_MAP_CLASS.COPY}
              color={AchieveTheme?.ascend?.colors?.neutral?.[900]}
              component="p"
            />
          </Grid>
        </Grid>
      </div>
    </Box>
  )
}

function EmployeeStoriesCarousel({ slides }) {
  return (
    <Carousel
      className={styles['employee-stories-carousel']}
      carouselItems={slides}
      itemComponent={Slide}
      variant="standard"
      idPrefix={`employee-stories-hero`}
      withIndicators
      classNameIndicators={styles['employee-stories-carousel-indicators']}
      classNameActiveDot={styles['employee-stories-carousel-active-dot']}
      classNameControls={styles['employee-stories-carousel-controls']}
      autoPlay
      infinityLoop
    />
  )
}

EmployeeStoriesHero.displayName = 'EmployeeStoriesHero'
export { EmployeeStoriesHero }
export default EmployeeStoriesHero
